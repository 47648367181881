/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.panel-categories h4 {
  color: #004987;
  font-size: 1.375rem;
  line-height: 1.625rem;
  text-transform: none;
  letter-spacing: 0; }

.panel-categories .editorialRichText-component-container p {
  margin-bottom: 1.75rem; }

.panel-categories img {
  border-radius: 20px;
  height: auto; }

.panel-categories .rounded-cat-tile {
  position: relative;
  display: block; }
  .panel-categories .rounded-cat-tile:hover .icon {
    background: #004987; }
  .panel-categories .rounded-cat-tile:hover .fa-arrow-right::before {
    color: #fff; }

.panel-categories .cat-link {
  position: absolute;
  top: 15px;
  left: 15px;
  font-family: "BrandonGrotesque-WebMedium"; }

.panel-categories .icon {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 14px;
  left: 14px;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  line-height: 22px; }

.cetaphil_redesign .panel-categories {
  margin: 25px; }
  @media (max-width: 1199.98px) {
    .cetaphil_redesign .panel-categories {
      margin: auto 11px;
      -ms-flex-wrap: inherit;
          flex-wrap: inherit;
      overflow: scroll;
      -ms-flex-pack: start;
          justify-content: flex-start; } }
  .cetaphil_redesign .panel-categories img {
    border-radius: 8px;
    min-height: 175px; }
    @media (max-width: 1023.98px) {
      .cetaphil_redesign .panel-categories img {
        min-height: 128px;
        min-width: 128px; } }
  .cetaphil_redesign .panel-categories ::-webkit-scrollbar {
    display: none; }
  .cetaphil_redesign .panel-categories .cat-link {
    font-family: "Lateral-CondensedBold"; }

.cetaphil_redesign ::-webkit-scrollbar {
  display: none; }

.panel-categories .image-wrapper {
  position: relative; }

.panel-categories .cat-link {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-family: "BrandonGrotesque-WebMedium";
  font-size: 1.125rem;
  line-height: 1.375rem;
  max-width: 4.375rem; }

.panel-categories .icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 2.1875rem;
  height: 2.1875rem;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  border-radius: 50%;
  background-color: var(--color-white); }
  .panel-categories .icon .arrow-right {
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem; }
    .panel-categories .icon .arrow-right:before {
      content: ''; }

.panel-categories .image-wrapper:hover .icon {
  background-color: var(--color-primary); }
  .panel-categories .image-wrapper:hover .icon .arrow-right {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDcwMjEgMS4xMzY5QzYuMjEwNTIgMC44NzcyMDggNS43ODk0OCAwLjg3NzIwNyA1LjUyOTc5IDEuMTM2OUM1LjI3MDI3IDEuMzk2NDIgNS4yNzAwOCAxLjgxNzE0IDUuNTI5MzcgMi4wNzY5TDguNzggNS4zMzMzNUgxLjMzMzMzQzAuOTY1MTQ1IDUuMzMzMzUgMC42NjY2NjggNS42MzE4MyAwLjY2NjY2OCA2LjAwMDAyQzAuNjY2NjY4IDYuMzY4MjEgMC45NjUxNDUgNi42NjY2OSAxLjMzMzMzIDYuNjY2NjlIOC43OEw1LjUyOTM3IDkuOTIzMTRDNS4yNzAwOCAxMC4xODI5IDUuMjcwMjcgMTAuNjAzNiA1LjUyOTc5IDEwLjg2MzFDNS43ODk0OCAxMS4xMjI4IDYuMjEwNTIgMTEuMTIyOCA2LjQ3MDIxIDEwLjg2MzFMMTAuNjI2MiA2LjcwNzEzQzExLjAxNjggNi4zMTY2IDExLjAxNjggNS42ODM0NCAxMC42MjYyIDUuMjkyOTFMNi40NzAyMSAxLjEzNjlaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo="); }
